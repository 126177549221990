import { API } from '../../../../Services/axios';
import { Col, Row, Space } from 'antd';
import { OrganisationProfile } from '../../../../Models/Operator';
import { useEffect, useState } from 'react';
import { useErrorModal } from '../../../../Components/Modals/ErrorModal';
import { useParams } from 'react-router-dom';
import ActiveProjectsCard from './Component/active-projects-card';
import AtiCard from './Component/ati-card';
import ChangeHistory from './Component/change-history';
import Claims from './Component/claims-card';
import Loader from '../../../../Components/Loader/Loader';
import OrgActions from './Component/org-actions';
import OrgProfileHeader from './Component/org-profile-header';
import ProfileCard from './Component/profile-card';
import UserProfile from './Component/user-profile';

// Display only first user of organization (profileData.users[0])
// until multi-users for one organization functionality will be implemented

const OrganizationProfile = () => {
  const { showErrorModal } = useErrorModal();
  const { id } = useParams();
  const [profileData, setProfileData] = useState<OrganisationProfile>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    API.get(`/api/v1/operator/organisation/${id}`)
      .then((response) => setProfileData(response.data))
      .catch((error) => showErrorModal({ error }))
      .finally(() => setLoading(false));
  }, [id]);

  if (loading) return <Loader />;

  return (profileData && (
    <Row gutter={[0, 32]} className="operator operator__full-width" justify="space-between">
      <Col span={24}>
        <OrgProfileHeader />
      </Col>
      <Col lg={{ span: 16, order: 0 }} span={24} order={1} className="operator__main-col">
        <Space size={16} direction="vertical">
          <ProfileCard profileData={profileData} />
          <UserProfile
            role={profileData.users[0].role}
            userName={`${profileData.users[0].userSurname} ${profileData.users[0].userName}`}
            phone={profileData.users[0].userPhone}
            email={profileData.users[0].userEmail}
          />
        </Space>
      </Col>
      <Col lg={{ span: 7 }} span={24} className="operator__action-col">
        <Space size={16} direction="vertical" className="operator__full-width">
          <OrgActions role={profileData.role} locked={profileData.locked} confirmed={profileData.confirmed} />
          <ChangeHistory history={profileData.organisationHistory} />
          <Claims claimsAmount={profileData.organisationClaims.length} />
          <ActiveProjectsCard
            role={profileData.role}
            activeAgreementsCount={profileData.users[0].activeAgreementsCount}
            activeOrdersCount={profileData.users[0].activeOrdersCount}
          />
          <AtiCard inn={profileData.inn} />
        </Space>
      </Col>
    </Row>
  ))!;
};

export default OrganizationProfile;
